import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../store/componentsSlice';
import { useAppDispatch } from '../store/hooks';
import DarkModeToggle from './shared/darkModeToggle';
import { Text } from './shared/text';
import { APP_URL, LOCAL_STORAGE_KEYS } from '../project/defines';
import { getKeyUpHandler } from '../utils/helpers';
import { reloadIsLoggedInFromStorage } from '../store/auth/authSlice';
import { getLocalStorageItem } from '../utils/helpers/localStorage';

interface AccountMenuProps {
  onClose?: () => void;
}

const AccountMenu: React.FC<AccountMenuProps> = React.forwardRef<HTMLDivElement, AccountMenuProps>(
  ({ onClose }, ref) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
      <div className="menu_cont thermor_user_launcher" data-elevation="2" ref={ref}>
        <group data-space="10" data-background="main-background" data-max-length="300">
          <div className="list_menu">
            <ul>
              <li
                onClick={() => {
                  navigate(APP_URL.Profile);
                  onClose?.();
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonProfil.texte</Text>
              </li>
              <li className="separator"></li>
              <li
                onClick={() => {
                  navigate(APP_URL.Societe);
                  onClose?.();
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonSociete.texte</Text>
              </li>
              <li
                onClick={() => {
                  navigate(APP_URL.Clients);
                  onClose?.();
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonClients.texte</Text>
              </li>

              <li className="separator"></li>
              <DarkModeToggle />
              <li className="separator"></li>
              {/* <li className="separator"></li>
            <li>
              <icon></icon>
              <group data-gap="10" data-align="center">
                <text>Version</text>
                <Separator vertical/>
                <text light="">2.0.0</text>
              </group>
            </li> */}
              <li
                onClick={() => {
                  let logout_url = getLocalStorageItem(LOCAL_STORAGE_KEYS.LogoutUrl);
                  const userToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
                  onClose?.();
                  
                  if (logout_url && userToken) {
                    //debugger
                    logout_url = logout_url.replace('#TOKEN', userToken)
                    logout_url = logout_url.replace('#REDIRECT_URL', `${window.location.origin}/wso/callback`)
                    window.location.href =  logout_url;
                  } else {
                    dispatch(logout());
                    dispatch(reloadIsLoggedInFromStorage());
                    navigate('/', { replace: true });
                  }
                }}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text dataWeight="700">global.menuUtilisateur.boutonDeconnexion.texte</Text>
              </li>
            </ul>
          </div>
        </group>
      </div>
    );
  }
);

export default AccountMenu;
