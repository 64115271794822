import { useNavigate } from 'react-router-dom';
import Button from '../../components/shared/button';
import { Text } from '../../components/shared/text';
import { APP_URL, WarrantyRequestSatcStatus, WarrantyRequestValideStatus, WarrantyType } from '../../project/defines';
import { getAppUrl, getWarrantyPrintUrl } from '../../project/helpers';
import { useAppSelector } from '../../store/hooks';
import { WarrantyRequest } from '../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../utils/hooks/useTranslation';

const StageFinal: React.FC<{
  request: WarrantyRequest;
}> = ({ request }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  let { typedemande, satc_attente, reference, valide } = request;
  const canPrint =
    (satc_attente === WarrantyRequestSatcStatus.PasDemande && valide === WarrantyRequestValideStatus.Transmise) ||
    (satc_attente === WarrantyRequestSatcStatus.Valide && valide === WarrantyRequestValideStatus.Valide);
  const attende = satc_attente === WarrantyRequestSatcStatus.PasDemande ? 'satc' : 'commande';
  const textStartPart =
    typedemande === WarrantyType.Retour
      ? 'garantie.demande.etapes.end.retour'
      : `garantie.demande.etapes.end.commande.${attende}`;
  return (
    <section>
      <wrapper data-space="10">
        <view>
          <group data-space="30" data-gap="30" data-direction="column" data-align="center" data-length="forcefit">
            <Text
              data-wrap="wrap"
              dataWeight="700"
              dataTextSize="medium"
              translateArgs={{ reference: reference ?? '' }}
            >{`${textStartPart}.1`}</Text>
            <group data-gap="30" data-justify="center" data-position="bottom">
              <Button
                large
                primary
                textFirst={false}
                title={t('garantie.demande.etapes.end.home')}
                icon="home"
                text="garantie.demande.etapes.end.home"
                onClick={() => navigate(getAppUrl(APP_URL.HomePage))}
              />
              {canPrint && (
                <Button
                  large
                  primary
                  textFirst={false}
                  title={t('garantie.demande.etapes.end.impression')}
                  icon="print"
                  text="garantie.demande.etapes.end.impression"
                  onClick={() => window.open(getWarrantyPrintUrl(request.uuid!, univers?.uuid!, lang), '_blank')}
                />
              )}
            </group>
          </group>
        </view>
      </wrapper>
    </section>
  );
};

export default StageFinal;
