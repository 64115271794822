//const baseKey: string = ((window as any).config as any).REACT_APP_API_URL;

const baseKey: string | undefined = process.env.REACT_APP_API_URL;

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(`${baseKey}-${key}`);
};

export const setLocalStorageItem = (key: string, value: any) => {
  return localStorage.setItem(`${baseKey}-${key}`, value);
};

export const removeLocalStorageItem = (key: string) => {
  return localStorage.removeItem(`${baseKey}-${key}`);
};

/**
   * @param {string} key - A key to identify the value.
   * @param {any} value - A value associated with the key.
   * @param {number} ttl- Time to live in seconds.
   */
export const setLocalStorageItemTtl = (key: string, value: any, ttl?: number | null) => {
  const data = {
      value: value,                  // store the value within this object
      ttl: Date.now() + ((ttl ?? 9999999) * 1000),   // store the TTL (time to live)
  };

  // store data in LocalStorage 
  return localStorage.setItem(`${baseKey}-${key}`, JSON.stringify(data));
};

/**
* @param {string} key - A key to identify the data.
* @param {any|null} callback - callback function if expire value.
* @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
*/
export const getLocalStorageItemTtl = (key: string, callback?: any) => {
  const jsonData: any = localStorage.getItem(`${baseKey}-${key}`);
  if (!jsonData) {     // if no value exists associated with the key, return null
      return null;
  }

  const data = JSON.parse(jsonData);

  // If TTL has expired, remove the data from localStorage and return null
  if (!data.ttl || Date.now() > data.ttl) {
    localStorage.removeItem(`${baseKey}-${key}`);
    if (callback) callback(data.value);
    return null;
  }

  // return data if not expired
  return data.value;
}

