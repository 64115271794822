import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import { useState } from 'react';
import Button from '../../components/shared/button';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import Popup from '../../components/shared/popup';
import { useConfirmationPopup } from '../../components/shared/popup/confirmationPopup';
import Separator from '../../components/shared/separator';
import { ApiErrorHandler } from '../../store/apiErrorHandler';
import { useDeleteClientMutation, useGetClientsQuery } from '../../store/auth/authApi';
import { addNotification } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useTranslation from '../../utils/hooks/useTranslation';
import ClientDetail from './detail';

const Clients: React.FC = () => {
  const { auth } = useAppSelector((state) => state);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [client, setClient] = useState<any>(null);
  const [newlyAddedKey, setNewlyAddedKey] = useState<string>('');
  const dispatch = useAppDispatch();
  const {
    data: clients,
    refetch,
    isFetching: isClientsFetching,
  } = useGetClientsQuery({ societe_uuid: auth?.societe?.uuid }, { skip: !auth?.societe?.uuid });
  const [trigger] = useDeleteClientMutation();

  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });

  const { open, popup } = useConfirmationPopup();

  const deleteClient = async (record: any) => {
    try {
      setIsLoading(true);
      await trigger(record.uuid);
      refetch();
      setIsLoading(false);
      dispatch(
        addNotification({
          type: 'success',
          message: t('global.notifications.client.deleteTitle'),
          description: t('global.notifications.client.deleteMessage'),
          duration: 5,
        })
      );
    } catch (error: any) {
      ApiErrorHandler(error, dispatch, t, 'global.notifications.client.clientErrorTitle');
    }
  };
  type TableRecord = Record<string, any>;
  const columns: ColumnType<TableRecord>[] = [
    {
      title: t('clients.mesClients.nom'),
      dataIndex: 'nom',
      sorter: true,
      width: 120,
    },
    {
      title: t('clients.mesClients.prenom'),
      dataIndex: 'prenom',
      sorter: true,
      width: 120,
    },
    {
      title: t('clients.mesClients.raisonSociale'),
      dataIndex: 'raison_sociale',
      sorter: true,
      width: 150,
    },
    {
      title: t('clients.mesClients.adresse'),
      dataIndex: 'adresse',
      sorter: true,
      width: 120,
    },
    {
      title: t('clients.mesClients.adresseComplement'),
      dataIndex: 'adresse_complement',
      sorter: true,
      width: 150,
    },
    {
      title: t('clients.mesClients.codePostal'),
      dataIndex: 'code_postal',
      sorter: true,
      width: 120,
    },
    {
      title: t('clients.mesClients.ville'),
      dataIndex: 'ville',
      sorter: true,
      width: 120,
    },
    {
      title: t('clients.mesClients.email'),
      dataIndex: 'email',
      sorter: true,
      //width: 150,
    },
    {
      dataIndex: 'commandButtons',
      width: 90,
      fixed: 'right',
      render: (value: string, record: Record<string, any>) => (
        <group data-wrap="no" data-gap="10">
          <Button
            mini
            icon="edit"
            onClick={() => {
              setOpenDetail(true);
              setClient(record);
              setIsNew(false);
            }}
          />
          <Separator vertical />
          <Button
            mini
            icon="delete"
            onClick={() =>
              open({
                title: t('clients.formulaires.suppression.suppressionLibelle'),
                message: `${t('societe.collaborateurs.formulaires.suppression.texte')} ${record.prenom} ${record.nom}`,
                positiveCallback: () => {
                  deleteClient(record);
                },
                positiveButtonText: t('clients.formulaires.boutons.suppression.libelle'),
                negativeButtonText: t('clients.formulaires.boutons.retour.libelle'),
                negativeCallBack: () => {},
              })
            }
          />
        </group>
      ),
    },
  ];

  return (
    <>
      {popup}
      {openDetail && (
        <Popup
          title={
            isNew
              ? 'clients.formulaires.ajout_modification.ajoutLibelle'
              : 'clients.formulaires.ajout_modification.modificationLibelle'
          }
          onClose={() => {
            setOpenDetail(false);
          }}
        >
          <ClientDetail
            client={client || {}}
            setOpenDetail={setOpenDetail}
            isNew={isNew}
            refetch={refetch}
            setNewlyAddedKey={setNewlyAddedKey}
          />
        </Popup>
      )}
      <group data-space-vertical="20" data-gap="10">
        <Button
          micro
          icon="add"
          primary
          onClick={() => {
            setOpenDetail(true);
            setClient({});
            setIsNew(true);
          }}
        />
      </group>
      <group data-background="main-background" data-direction="column">
        <Table
          view={ListViewType.Table}
          columns={columns}
          isLoading={isClientsFetching || isLoading}
          dataSource={clients ?? []}
          pagination={false}
          pagingProps={pagingProps.pagination}
          setSorter={setSort}
          sort={sort}
          onHeaderRow={(row) => {
            return {
              onClick: () => {
                if (newlyAddedKey) {
                  setNewlyAddedKey('');
                }
              },
            };
          }}
          defaultSortField="nom"
          keyField="uuid"
          size={'small' as SizeType}
          bordered={true}
          newlyAddedKey={newlyAddedKey}
          tableLayout="auto"
        />
        <PaginationToolbar {...pagingProps} total={clients ? clients.length : 0} />
      </group>
    </>
  );
};

export default Clients;
