import { useState, useEffect } from 'react';
import Button from '../../components/shared/button';
import { Text } from '../../components/shared/text';
import { formatNumberEuro, getApiUrl, getUniversFromUrl, checkAccess } from '../../project/helpers';
import { useAppSelector } from '../../store/hooks';
import useTranslation from '../../utils/hooks/useTranslation';
import Popup from '../../components/shared/popup';
import CommandPopupContent from './commandPopupContent';
import DevisSuccessPopupContent from './devisSuccessPopupContent';
import Separator from '../../components/shared/separator';
import { APP_URL } from '../../project/defines';

const CartFooter: React.FC<{ cartData: any; isPending: boolean }> = ({ cartData, isPending }) => {
  const { t } = useTranslation();
  let hasDeee = !!cartData.total_d3e_ht && parseFloat(cartData.total_d3e_ht) !== 0,
    hasEcoContr = !!cartData.total_eco_contr && parseFloat(cartData.total_eco_contr) !== 0;
  const auth = useAppSelector((state) => state.auth);

  const [devisSuccessPopupOpen, setDevisSuccessPopupOpen] = useState(false);
  const [devisPdfUrl, setDevisPdfUrl] = useState<any>();

  const [commandPopupOpen, setCommandPopupOpen] = useState(false);
  const [devisPopupOpen, setDevisPopupOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<any>(null);

  useEffect(() => {
    if (redirectUrl) {
      window.open(`${APP_URL.RedirectResource}?url=${escape(redirectUrl)}`, '_blank');
      setRedirectUrl(null);
    }
  }, [redirectUrl]);

  return (
    <>
      <div className="title" data-direction="column">
        <group data-gap="10" data-space="10">
          <group data-width="auto" data-direction="column" data-contain="" data-max-length="fit">
            <Text light data-wrap="wrap">
              panier.tarifEnVigueur
            </Text>
          </group>

          <group data-width="auto" data-direction="column" data-position="right" data-gap="10" data-space-vertical="5">
            <group data-align="center" data-gap="10" data-wrap="no">
              <group data-align="center" data-wrap="no" data-length="140" data-justify="end">
                <Text dataWeight="700">commandes.commande.table.totalHt</Text>
              </group>
              <group data-align="center" data-wrap="no" data-space-horizontal="10" data-max-length="100">
                <text>{formatNumberEuro(cartData.total_ht)}</text>
              </group>
            </group>
            {hasDeee && (
              <group data-align="center" data-gap="10" data-wrap="no">
                <group data-align="center" data-wrap="no" data-length="140" data-justify="end">
                  <Text dataWeight="700">commandes.commande.table.totalDeeeHt</Text>
                </group>
                <group data-align="center" data-wrap="no" data-space-horizontal="10" data-max-length="100">
                  <text>{formatNumberEuro(cartData.total_d3e_ht)}</text>
                </group>
              </group>
            )}
            {hasEcoContr && (
              <group data-align="center" data-gap="10" data-wrap="no">
                <group data-align="center" data-wrap="no" data-length="140" data-justify="end">
                  <Text dataWeight="700">commandes.commande.table.totalEcoContr</Text>
                </group>
                <group data-align="center" data-wrap="no" data-space-horizontal="10" data-max-length="100">
                  <text>{formatNumberEuro(cartData.total_eco_contr)}</text>
                </group>
              </group>
            )}

            <group data-align="center" data-gap="10" data-wrap="no">
              <group data-align="center" data-wrap="no" data-length="140" data-justify="end">
                <Text dataWeight="700">commandes.commande.table.totalTtc</Text>
              </group>
              <group data-align="center" data-wrap="no" data-space-horizontal="10" data-max-length="100">
                <text>{formatNumberEuro(cartData.total_ttc)}</text>
              </group>
            </group>
          </group>
        </group>

        {!isPending && (
          <>
            <Separator horizontal data-margin="none" />
            <group data-align="center" data-gap="10" data-wrap="no" data-space="10" data-background="highlight">
              <group
                data-position="right"
                //data-wrap="no"
                data-width="auto"
                data-gap="10"
                data-length="forcefit"
              >
                <Button
                  data-length="forcefit"
                  outline
                  text={t('panier.panierEnCours.export')}
                  onClick={() => {
                    let url = `${getApiUrl(getUniversFromUrl())}/paniers/reseaux/${
                        cartData.lignes_panier[0].reseau_uuid
                      }/export`;

                    setRedirectUrl(url);
                  }}
                />
                {cartData.devis_active && (
                  <Button
                    data-length="forcefit"
                    primary
                    text={t('panier.panierEnCours.devis')}
                    onClick={() => {
                      setDevisPopupOpen(true);
                    }}
                  />
                )}
                {checkAccess(auth, 'commande') && (
                  <Button
                    data-length="forcefit"
                    primary
                    text={t(`panier.panierEnCours.${cartData.isEnCompte ? 'command' : 'payer'}`)}
                    onClick={() => {
                      setCommandPopupOpen(true);
                    }}
                  />
                )}
              </group>
            </group>
          </>
        )}
      </div>
      {devisPopupOpen && (
        <Popup
          fixSize="medium"
          title={t('panier.devisModal.title')}
          onClose={() => {
            setDevisPopupOpen(false);
          }}
        >
          <CommandPopupContent
            cartData={cartData}
            setDevisPdfUrl={setDevisPdfUrl}
            setDevisSuccessPopupOpen={setDevisSuccessPopupOpen}
            isDevis
            onClose={() => {
              setDevisPopupOpen(false);
            }}
          />
        </Popup>
      )}
      {commandPopupOpen && (
        <Popup
          fixSize="medium"
          title={t('panier.commandeModal.title')}
          onClose={() => {
            setCommandPopupOpen(false);
          }}
        >
          <CommandPopupContent
            cartData={cartData}
            onClose={() => {
              setCommandPopupOpen(false);
            }}
          />
        </Popup>
      )}
      {devisSuccessPopupOpen && (
        <Popup
          title={t('panier.resultDevisModal.title')}
          onClose={() => {
            setDevisSuccessPopupOpen(false);
            setDevisPdfUrl('');
          }}
        >
          <DevisSuccessPopupContent
            pdfUrl={devisPdfUrl}
            onClose={() => {
              setDevisSuccessPopupOpen(false);
              setDevisPdfUrl('');
            }}
          />
        </Popup>
      )}
    </>
  );
};
export default CartFooter;
