import Button from '../../components/shared/button';
import { getDocGARight } from '../../project/helpers';
import { SatcFamillesModel } from '../../store/satc/satcModels';
import { copyToClipboard } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';


const TabDocTech: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { doc_technique } = data;
  const { t } = useTranslation();

  return (

<group data-space="5">
      <group data-space-vertical="15" direction="row" data-gap="10" data-type="grid" data-grid-size="500" data-align="start">        
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t('satc.detail.tabs.docTech.produit')}</th>
                <th>{t('satc.detail.tabs.docTech.droits')}</th>
                <th data-sticky="right" style={{width:40}}>{t('satc.detail.tabs.videoTutos.second')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(doc_technique.PFS_DOC_PRODUIT).map((key: any) => {
                let item = doc_technique.PFS_DOC_PRODUIT[key];
                let tags = getDocGARight(item.tags);

                return (
                  <tr key={key}>
                    <td>
                      <wrap>
                        <a data-type="link" href={item.download} target="_blank" rel="noreferrer">
                          <text data-wrap="wrap" data-clamp="2">{item.title}</text>
                        </a>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap" data-clamp="2">{tags.join(', ')}</text>
                      </wrap>
                    </td>
                    <td data-sticky="right">
                      <Button extra onClick={() => copyToClipboard(t, item.download)}>
                        <icon>content_copy</icon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t('satc.detail.tabs.docTech.sav')}</th>
                <th>{t('satc.detail.tabs.docTech.droits')}</th>
                <th  data-sticky="right" style={{width:40}}>{t('satc.detail.tabs.videoTutos.second')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(doc_technique.PFS_DOC_SAV).map((key: any) => {
                let item = doc_technique.PFS_DOC_SAV[key];
                let tags = getDocGARight(item.tags);

                return (
                  <tr key={key}>
                    <td>
                      <wrap>
                        <a data-type="link" href={item.download} target="_blank" rel="noreferrer">
                          <text  data-wrap="wrap" data-clamp="2">{item.title}</text>                          
                        </a>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-clamp="2" data-wrap="wrap">{tags}</text>
                      </wrap>
                    </td>
                    <td data-sticky="right">
                      <Button extra onClick={() => copyToClipboard(t, item.download)}>
                        <icon>content_copy</icon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </group>
</group>


  );
};

export default TabDocTech;
