import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../components/shared/inputs/input';
import Select from '../../../../components/shared/inputs/select';
import Loader from '../../../../components/shared/loader';
import { WarrantyStage2FormFields } from '../../../../project/defines';
import { ordersApi } from '../../../../store/orders/ordersApi';
import { WarrantyRequest } from '../../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../../utils/hooks/useTranslation';

const FieldsWarrantyArticle: React.FC<{ request: WarrantyRequest }> = ({ request }) => {
  const { t } = useTranslation();
  const {
    data: orders,
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
  } = ordersApi.endpoints.getProductOrders.useQueryState(request.produit_uuid!, { skip: !request?.produit_uuid });
  const orderOptions = useMemo(() => {
    const result = [{ value: '', text: t('garantie.demande.etapes.2.form.optionDefaut') }];
    orders?.forEach((d) => result.push({ text: d.reference!, value: d.uuid }));
    return result;
  }, [orders, t]);

  const { control, watch, setValue } = useFormContext();
  const dossier = watch(WarrantyStage2FormFields.dossier);
  const commande = watch('commande');

  useEffect(() => {
    setValue('commande', orders?.find((o) => o.reference === dossier)?.uuid ?? '');
  }, [dossier, setValue, orders]);

  useEffect(() => {
    if (commande) {
      const currentCommande = orders?.find((o) => o.uuid === commande);
      setValue(WarrantyStage2FormFields.dossier, currentCommande?.reference ?? commande);
    }
  }, [commande, setValue, orders]);

  return isOrdersLoading || isOrdersFetching ? (
    <Loader />
  ) : (
    <>
      <Input
        label="garantie.demande.etapes.2.form.ref_facture"
        dataLength="autofit"
        size="large"
        aria-invalid="false"
        placeholder={t('garantie.demande.etapes.2.form.ref_facture')}
        translateLabel
        isGroup
        name={WarrantyStage2FormFields.dossier}
        control={control}
      />
      <Select
        label="garantie.demande.etapes.2.form.commande"
        dataLength="autofit"
        size="large"
        aria-invalid="false"
        placeholder={t('garantie.demande.etapes.2.form.commande')}
        translateLabel
        options={orderOptions}
        isGroup
        name="commande"
        control={control}
      />
    </>
  );
};

export default FieldsWarrantyArticle;
