import { Link } from 'react-router-dom';
import { useLogo } from '../brands/useLogoHook';
import { Family } from '../store/family/familyModels';
import { APP_URL, DEFAULT_DATE_FORMAT } from '../project/defines';
import { formatDate } from '../utils/helpers';
import EmptyPicture from './emptyPicture';
import { Text } from './shared/text';
import { getAppUrl } from '../project/helpers';
import classNames from 'classnames';

const FamilyWidget: React.FC<{ family: Family }> = ({ family }) => {
  const { uuid, image, date_debut, date_fin, libelle, is_masked } = family;

  const logo = useLogo();
  const logoOrEmpty = logo ? <group data-justify="center">{logo}</group> : <EmptyPicture />;

  return (
    <group className={classNames({ masked_family: is_masked })} data-direction="column" data-border="" data-radius="10">
      <Link data-type="group" to={getAppUrl(APP_URL.Family, { routeParams: { id: uuid } })}>
        {(date_debut || date_fin) && (
          <group data-direction="column" data-space="10">
            {date_debut && (
              <group data-align="center" data-gap="5" data-text-size="12">
                <Text data-weight="700" data-height="20">
                  {date_debut ? 'vignettes.familles.dateDebut' : ''}
                </Text>
                <Text data-height="20">{date_debut && formatDate(date_debut, DEFAULT_DATE_FORMAT)}</Text>
              </group>
            )}
            {date_fin && (
              <group data-align="center" data-gap="5" data-text-size="12">
                <Text data-weight="700" data-height="20">
                  {date_fin ? 'vignettes.familles.dateFin' : ''}
                </Text>
                <Text data-height="20">{date_fin && formatDate(date_fin, DEFAULT_DATE_FORMAT)}</Text>
              </group>
            )}
          </group>
        )}

        <group data-space="10" data-ratio="1:1" data-shrink="no">
          <picture data-radius="5" data-mask="">
            {image ? <img src={image} alt="" /> : logoOrEmpty}
          </picture>
        </group>

        <group data-gap="5" data-space="10" data-align="center">
          <text data-wrap="wrap" data-weight="700">
            {libelle}
          </text>
        </group>
      </Link>
    </group>
  );
};

export default FamilyWidget;
