import { useParams } from 'react-router-dom';
import BackButton from '../../../components/backButton';
import Loader from '../../../components/shared/loader';
import Separator from '../../../components/shared/separator';
import { Text } from '../../../components/shared/text';
import { DEFAULT_DATE_FORMAT, OrderStatus } from '../../../project/defines';
import { useAppSelector } from '../../../store/hooks';
import { Order } from '../../../store/orders/orderModels';
import { useGetOrdersDetailsQuery } from '../../../store/orders/ordersApi';
import { formatDate } from '../../../utils/helpers';
import useTranslation from '../../../utils/hooks/useTranslation';
import OrderDetailArticles from './articles';

/**

  * Retourns status translation
  * @param status
  * @param t
  * @returns {*}
  */
export const getStatusText = (status: string, t: Function) => {
  let result = status;
  // Cf Commande.php pour la liste des etats possibles

  switch (status) {
    case OrderStatus.Creee:
      result = t('commandes.monHistoriqueCommandes.etats.creee');
      break;
    case OrderStatus.Attente:
      result = t('commandes.monHistoriqueCommandes.etats.enAttente');
      break;
    case OrderStatus.AttenteSATC:
      result = t('commandes.monHistoriqueCommandes.etats.enAttenteSATC');
      break;
    case OrderStatus.AttenteBanque:
      result = t('commandes.monHistoriqueCommandes.etats.enAttenteBanque');
      break;
    case OrderStatus.AttentePaiement:
      result = t('commandes.monHistoriqueCommandes.etats.enAttentePaiement');
      break;
    case OrderStatus.Soldee:
      result = t('commandes.monHistoriqueCommandes.etats.soldee');
      break;
    case OrderStatus.Expediee:
      result = t('commandes.monHistoriqueCommandes.etats.expediee');
      break;
    case OrderStatus.Validee:
      result = t('commandes.monHistoriqueCommandes.etats.validee');
      break;
    case OrderStatus.RefuseePrestatairePaiement:
      result = t('commandes.monHistoriqueCommandes.etats.refuseePrestatairePaiement');
      break;
    case OrderStatus.Annulee:
      result = t('commandes.monHistoriqueCommandes.etats.annulee');
      break;
  }
  return result;
};

const OrderDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const univers_uuid = useAppSelector((state) => state.siteData?.universInfo?.univers?.uuid) ?? '';
  const { data, isLoading, isFetching } = useGetOrdersDetailsQuery({ lang, univers_uuid, order_uuid: id ?? '' });
  const order = data ?? {} as Order;
  const { uuid, reference, reseau, date_creation_commande, etat, telephone, livraison_nom, livraison_adresse, livraison_adresse_complement, livraison_code_postal, livraison_ville, } = order;


  return <section>
    {(isLoading || isFetching) && <Loader />}
    <wrapper>
      <group data-radius="15" data-space="10" data-background="highlight">
        <Text dataWeight="700" accent dataTextSize="large" dataWrap=''>
          commandes.commande.title
        </Text>
        <group data-position="right" data-length='100'><BackButton /></group>
      </group>
      {uuid && <group data-space="30" data-gap="20">
        <group data-gap="15" data-length="forcefit" data-width="auto" data-fit="1">
          <group data-direction="column" data-gap="5">
            <group data-gap="10"  data-wrap="no" data-align='center'>
              <Text data-wrap="wrap">commandes.commande.libelle</Text>
              <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">{reference}</Text>
            </group>
            <space></space>
            <group data-gap="10"  data-wrap="no">
              <Text data-wrap="wrap" data-length='140'>commandes.commande.reseau</Text>
              <Text data-wrap="wrap"  data-weight="700" >{reseau.nom}</Text>
            </group>
            <Separator horizontal="dotted" data-margin="10"/>
            <group data-gap="10"  data-wrap="no">
              <Text data-wrap="wrap" data-length='140'>commandes.commande.dateCommande</Text>
              <Text data-wrap="wrap"  data-weight="700" >{formatDate(date_creation_commande, DEFAULT_DATE_FORMAT)}</Text>
            </group>
            <Separator horizontal="dotted" data-margin="10"/>
            <group data-gap="10"  data-wrap="no">
              <Text data-wrap="wrap" data-length='140'>commandes.commande.etat</Text>
              <Text data-wrap="wrap"  data-weight="700" >{getStatusText(etat, t)}</Text>
            </group>
            <Separator horizontal="dotted" data-margin="10" />
            <group data-gap="10"  data-wrap="no">
              <Text data-wrap="wrap" data-length='140'>commandes.commande.contact</Text>
              <Text data-wrap="wrap"  data-weight="700" >  <a href={"tel:" + telephone}>{telephone}</a></Text>
            </group>
            <Separator horizontal="dotted" data-margin="10"/>
            <group data-gap="10"  data-wrap="no">
              <group data-fit='1'>
                <Text data-wrap="wrap"  data-length='140'>commandes.commande.adresseLivraison</Text>
              </group>
              <group data-direction="column">
                <Text data-wrap="wrap"  data-weight="700"  data-line="1.5">{livraison_nom}</Text>
                <Text data-wrap="wrap"  data-weight="700"  data-line="1.5">{livraison_adresse}</Text>
                <Text data-wrap="wrap"  data-weight="700"  data-line="1.5">{livraison_adresse_complement}</Text>
                <Text data-wrap="wrap"  data-weight="700"  data-line="1.5">{livraison_code_postal}</Text>
                <Text data-wrap="wrap"  data-weight="700"  data-line="1.5">{livraison_ville}</Text>
              </group>
            </group>
          </group>
        </group>
        <group data-length="forcefit" data-width="auto" data-fit="1">
          <OrderDetailArticles order={order} />
        </group>
      </group>}
    </wrapper>
  </section>;

};

export default OrderDetail;
