import { SizeType } from 'antd/es/config-provider/SizeContext';
import { Key, useEffect, useState } from 'react';
import Button from '../../../components/shared/button';
import Table, { ColumnType } from '../../../components/shared/list/table';
import Separator from '../../../components/shared/separator';
import { Text } from '../../../components/shared/text';
import { SvgLoader } from '../../../components/svg';
import { OrderStatus } from '../../../project/defines';
import { formatNumberEuro,  getApiUrl, getUniversFromUrl } from '../../../project/helpers';
import { addNotification } from '../../../store/componentsSlice';
import { useAddToCartHook, useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Order } from '../../../store/orders/orderModels';
import { useCheckPaymentMutation } from '../../../store/payment/paymentApi';
import useTranslation from '../../../utils/hooks/useTranslation';
import { isValidJSON } from '../../../components/shared/list/utils';

const OrderDetailArticles: React.FC<{ order: Order }> = ({ order }) => {
  const dispatch = useAppDispatch();
  const univers_uuid = useAppSelector((state) => state.siteData?.universInfo?.univers?.uuid) ?? '';

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const { t } = useTranslation();
  const { articles, total_deee_ht, total_ttc, total_ht, etat, is_en_compte, reseau } = order;
  let has_d3e = (total_deee_ht as number) > 0;
  const [showMessage, setShowMessage] = useState(false);

  const {
    addToCart,
    queryState: { isLoading: isAddingToCart },
  } = useAddToCartHook();
  const [triggerPaymentCheck, { isLoading: isPaymentLoading }] = useCheckPaymentMutation();

  const onClickAddCart = async () => {
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.forEach((key) => {
        (async (key) => {
          let data = {
            article_uuid: key as string,
            reseau_uuid: reseau.uuid,
            univers_uuid,
            quantite: articles.find((a) => a.uuid === key)!.quantite,
            en_attente: false,
          };

          const result = await addToCart(data);

          if (result) {
            setShowMessage(true);
          }
        })(key);
      });
    }
  };

  useEffect(() => {
    if (showMessage) {
      let to = setTimeout(() => {
        dispatch(
          addNotification({
            message: t('global.notifications.paniers.titleSingle'),
            description: t('global.notifications.paniers.created'),
            type: 'success',
            duration: 5,
          })
        );
        setShowMessage(false);
      }, 300);

      return () => {
        clearTimeout(to);
      };
    }
  }, [showMessage]);

  const onClickPayment = async () => {
    if (order.payment_url) {
      debugger
      const paymentResult: any = await triggerPaymentCheck({ url: `${getApiUrl(getUniversFromUrl())}/payment/${order.uuid}`}); //url: order.payment_url });

      if (paymentResult.error) {
        let description = '';
        if (typeof paymentResult.error === 'string') {
          description = paymentResult.error;
        } else if (typeof paymentResult.error.data === 'string') {
          if (isValidJSON(paymentResult.error.data)) {
            description = JSON.parse(paymentResult.error.data).error;
          } else {
            description = paymentResult.error.data.error;
          }
        }
        dispatch(
          addNotification({
            type: 'error',
            message: t('global.notifications.commandes.errorTitle'),
            description: t(description),
            duration: 5,
          })
        );
      } else {
        document.write(paymentResult.data);
      }
    }
  };

  const columns: ColumnType[] = [
    {
      key: 'reference',
      dataIndex: 'reference',
      title: t('commandes.commande.table.articles'),
      render: (v, record) => `[${record.reference}] + ${record.libelle}`,
      //width: 250,
    },

    {
      key: 'quantite',
      dataIndex: 'quantite',
      title: t('commandes.commande.table.quantite'),
      //width: 150,
      align: 'center',
    },
    {
      key: 'total_ht',
      dataIndex: 'total_ht',
      title: t('commandes.commande.table.prix'),
      render: (v) => formatNumberEuro(v as number),
      width: 100,
      align: 'right',
    },
  ];

  if (has_d3e) {
    columns.splice(2, 0, {
      key: 'd3e_unitaire',
      dataIndex: 'd3e_unitaire',
      title: t('commandes.commande.table.deee'),
      render: (v) => formatNumberEuro(v as number),
      width: 150,
      align: 'right',
    });
  }

  return (
    <group data-direction="column" data-border="" data-radius="5" data-contain="">
      <group data-space="10" data-gap="10" data-align="center" data-background="main-lighter">
        <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">
          commandes.commande.table.articles
        </Text>
      </group>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: (selected, selectedRows) => {
            setSelectedRowKeys([...selected]);
          },
        }}
        keyField="uuid"
        dataSource={articles}
        pagination={false}
        columns={columns}
        size={'small' as SizeType}
      />
      <group>
        <group data-space="10" data-gap="10" data-fit="1">
          <Button
            primary
            title={t('commandes.commande.addCart.title')}
            text="commandes.commande.addCart.label"
            disabled={!selectedRowKeys.length || isAddingToCart}
            onClick={onClickAddCart}
          >
            {isAddingToCart && (
              <icon>
                <SvgLoader />
              </icon>
            )}
          </Button>
        </group>
        <group data-position="right" data-max-length="300" data-direction="column">
          <group data-gap="5" data-align="center">
            <Text data-wrap="wrap" data-length="autofit" data-text-align="right" data-space="5">
              commandes.commande.table.totalHt
            </Text>
            <Text
              data-wrap="wrap"
              data-accent=""
              data-weight="700"
              data-space="10"
              data-length="100"
              data-text-align="right"
            >
              {formatNumberEuro(total_ht ?? 0)}
            </Text>
          </group>
          {has_d3e && (
            <group data-gap="5" data-align="center">
              <Text data-wrap="wrap" data-length="autofit" data-text-align="right" data-space="5">
                commandes.commande.table.totalDeeeHt
              </Text>
              <Text
                data-wrap="wrap"
                data-accent=""
                data-weight="700"
                data-space="10"
                data-length="100"
                data-text-align="right"
              >
                {formatNumberEuro(total_deee_ht ?? 0)}
              </Text>
            </group>
          )}
          <Separator data-margin="10" horizontal="" />
          <group data-gap="5" data-align="center">
            <Text data-wrap="wrap" data-length="autofit" data-text-align="right" data-space="5">
              commandes.commande.table.totalTtc
            </Text>
            <Text
              data-wrap="wrap"
              data-accent=""
              data-weight="700"
              data-space="10"
              data-length="100"
              data-text-align="right"
            >
              {formatNumberEuro(total_ttc ?? 0)}
            </Text>
          </group>
        </group>
      </group>
      {etat === OrderStatus.AttentePaiement && order.payment_url && !is_en_compte && (
        <group data-position="right" data-space="10" data-length="200" data-direction="column">
          <Button
            primary
            title={t('panier.panierEnCours.command')}
            text="panier.panierEnCours.payer"
            onClick={onClickPayment}
            disabled={isPaymentLoading}
          >
            {isPaymentLoading && (
              <icon>
                <SvgLoader />
              </icon>
            )}
          </Button>
        </group>
      )}
    </group>
  );
};

export default OrderDetailArticles;
