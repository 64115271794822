import React from 'react';
import Button from '../../components/shared/button';
import { useConfirmationPopup } from '../../components/shared/popup/confirmationPopup';
import Separator from '../../components/shared/separator';
import PopupDemo from './popupDemo';
import { SimpleContent, TabsContent } from './popupTemplates';
import Typeface from './typeface';

const Popups: React.FC = () => {
  const { open, popup: cofirmationPopup } = useConfirmationPopup();
  return (
    <view>
      <view>
        <toolbar></toolbar>
        <view data-vertical="true" data-space="20">
          <div className="grouped_box">
            <div className="grouped_box_wrap" data-space="20">
              <group data-space="20" data-border="" data-gap="20" data-align="center" data-background="main-background">
                <PopupDemo>
                  <Typeface />
                </PopupDemo>
                <Separator vertical />
                <text>This Will Open Regular Window</text>
              </group>

              <group data-space="20" data-border="" data-background="main-background">
                <PopupDemo fullscreen>
                  <Typeface />
                </PopupDemo>
              </group>
              <group data-space="20" data-border="" data-background="main-background">
                <PopupDemo title="Title Goes Here" buttonText="Open Classic with Simple Content">
                  <SimpleContent />
                </PopupDemo>
              </group>

              <group data-space="20" data-border="" data-background="main-background">
                <PopupDemo title="Title Goes Here" buttonText="Open Colored with Tab Content" coloredTitle>
                  <TabsContent />
                </PopupDemo>
              </group>
            </div>
            <div className="grouped_box_wrap" data-space="20">
              <group data-space="20" data-border="" data-background="main-background">
                <Button
                  text="open Confirmation popup"
                  onClick={() =>
                    open({
                      title: 'This Cannot Be Undone',
                      message: 'Are you sure you want to delete this item?',
                      positiveCallback: () => {},
                      positiveButtonText: 'Yes, Delete',
                      negativeButtonText: 'Cancel',
                      negativeCallBack: () => {},
                    })
                  }
                />
                {cofirmationPopup}
              </group>
              <space data-size="20"></space>
              <Separator horizontal />
              <space data-size="20"></space>
              <group data-space="20" data-border="" data-background="main-background">
                <div className="button outline large">
                  <text>Prepend Warning Message</text>
                </div>
              </group>
              <group data-space="20" data-border="" data-background="main-background">
                <div className="button outline large">
                  <text>Append Message</text>
                </div>
              </group>
            </div>
          </div>
        </view>
      </view>
    </view>
  );
};
export default Popups;
