import classNames from 'classnames';
import Button from '../../components/shared/button';
import Checkbox from '../../components/shared/inputs/checkbox';
import { Text } from '../../components/shared/text';
import { useAppSelector, useGetFamiliesQueryState } from '../../store/hooks';
import { getKeyUpHandler } from '../../utils/helpers';
import { SearchResultFilter, searchTypesConfig } from './search';

export const LeftFilter: React.FC<SearchResultFilter> = ({
  searchTypes,
  appliedFilter,
  setType,
  clearFilter,
  toggleFamily,
  isTypeSelected,
}) => {
  const { type } = appliedFilter;
  const { isFetching, isLoading } = useGetFamiliesQueryState();
  const families = useAppSelector((s) => s.siteData.families) ?? [];
  return searchTypes.length > 0 ? (
    <group
      // data-sticky="150"
      data-space="10"
      data-background="main-background"
      data-border=""
      data-direction="column"
      data-length="300"
      data-radius="10"
      data-name="filter_bar"
    >
      <div className="list_menu">
        <ul>
          <li className="title">
            <icon></icon>
            <Text data-weight="700">recherche.filtres.header</Text>
          </li>
          <li className="separator"></li>
          {searchTypesConfig
            .filter((f) => searchTypes.includes(f.type))
            .map((t) => (
              <li
                key={t.type}
                className={classNames({ selected: isTypeSelected(t.type) })}
                onClick={() => setType(t.type)}
                onKeyUp={getKeyUpHandler()}
              >
                <icon></icon>
                <Text>{t.filterLabel}</Text>
                {isTypeSelected(t.type) && (
                  <Button
                    className="clear"
                    onClick={(e) => {
                      e.stopPropagation();
                      clearFilter();
                    }}
                    rounded
                    mini
                    data-position="right"
                  >
                    <icon data-icon-size="16">close</icon>
                  </Button>
                )}
              </li>
            ))}
          <li className="separator"></li>
          {/*(isFetching || isLoading) && <Loader />*/}
          {!!type &&
            !(isFetching || isLoading) &&
            families?.map((f, i) => {
              const value = !!appliedFilter.main_familles_uuids?.includes(f.uuid);
              return (
                <li key={f.uuid}>
                  <Checkbox
                    classic
                    noInk
                    label={f.nom}
                    value={value as any}
                    onChange={() => toggleFamily(f.uuid, !value)}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </group>
  ) : (
    <></>
  );
};
