import { SESSION_STORAGE_KEYS } from '../../project/defines';
import { disableArticleAddtoCartButton, getProSSO, showArticleCreditCardIcon, redirectToUrl } from '../../project/helpers';
import { Article } from '../../store/article/articleModels';
import { useAddToCartHook, useAppSelector } from '../../store/hooks';
import { isDisabledLogin } from '../../store/sideData/siteDataSlice';
import { UniversInfo } from '../../store/univers/universModels';
import { setSessionStorageItem } from '../../utils/helpers/sessionStorage';
import useTranslation from '../../utils/hooks/useTranslation';
import Button from '../shared/button';
import { Text } from '../shared/text';
import { SvgLoader } from '../svg';

const AddToCardButton: React.FC<{ article: Article; }> = ({ article }) => {
  const { t } = useTranslation();
  const { listeSSO, univers } = useAppSelector((state) => state.siteData.universInfo) ?? ({} as UniversInfo);
  const disabledLogin = useAppSelector(isDisabledLogin);

  const user = useAppSelector((state) => state.auth);
  const isLoggedIn = user.active;
  const proSSOList = getProSSO(listeSSO);
  const { articleReseau } = article;
  const disabled = disableArticleAddtoCartButton(article, user, listeSSO ?? [], !!disabledLogin);

  const { addToCart, queryState: { isLoading } } = useAddToCartHook();

  const onClickAddToCard = () => {
    let data = {
      article_uuid: article.uuid,
      reseau_uuid: articleReseau.reseau.uuid,
      univers_uuid: univers!.uuid,
      quantite: 1,
      en_attente: false,
    };
    if (isLoggedIn) {
      addToCart(data);
    } else if (proSSOList?.length === 1) {
      setSessionStorageItem(SESSION_STORAGE_KEYS.ArticleAddToCard, JSON.stringify(data));
      redirectToUrl(proSSOList[0].url + `?callback=${window.location.origin}/wso/callback`)
    }
  };

  return (
    <Button
      large
      wide
      data-text-align="left"
      disabled={disabled || isLoading}
      title={t(
        isLoggedIn || disabledLogin
          ? 'vignettesArticle.buttons.ajoutPanier.titre'
          : 'global.connexionPanel.boutonConnexion.titre'
      )}
      onClick={onClickAddToCard}
    >
      <Text>vignettesArticle.buttons.ajoutPanier.libelle</Text>
      {showArticleCreditCardIcon(article, user) && <icon>credit_card</icon>}
      <icon>shopping_basket</icon>

      {isLoading && <icon>
        <SvgLoader />
      </icon>}
    </Button>
  );
};

export default AddToCardButton;
