import { SatcFamillesModel } from '../../store/satc/satcModels';
import { formatDate } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';
import { DEFAULT_DATE_FORMAT } from '../../project/defines';

const TabEvolutions: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { evolutions } = data;
  const { t } = useTranslation();

  return (
    <group data-space="5">
      <group data-space-vertical="15" direction="row" data-gap="10" data-type="grid" data-grid-size="500" data-align="start">
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t("satc.detail.tabs.evolutions.first")}</th>
                <th>{t("satc.detail.tabs.evolutions.second")}</th>
                <th>{t("satc.detail.tabs.evolutions.third")}</th>
                <th>{t("satc.detail.tabs.evolutions.fourth")}</th>
                <th>{t("satc.detail.tabs.evolutions.fifth")}</th>
              </tr>
            </thead>
            <tbody>
              {evolutions.map((item: any) => {
                let debut = "";
                let fin = "";
                if (item.debut) {
                  debut = formatDate(item.debut, DEFAULT_DATE_FORMAT);
                }
                if (item.fin) {
                  fin = formatDate(item.fin, DEFAULT_DATE_FORMAT);
                }
                return (
                  <tr key={item.uuid}>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">{item.modele}</text>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">{item.evolution}</text>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">{debut}</text>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">{fin}</text>
                      </wrap>
                    </td>
                    <td>
                      <wrap></wrap>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </group>
    </group>
  );
};

export default TabEvolutions;
