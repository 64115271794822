import React, { useEffect, useMemo, useState } from 'react';
import { Location, Navigate, Route, Routes, useLocation, useParams, useNavigate } from 'react-router-dom';
import { BrandComponent, useBrandComponent } from '../brands/useBrandComponentHook';
import Layout from '../layout';
import { APP_URL, DEFAULT_LANGUAGE, DEFAULT_COUNTRY, DEFAULT_COUNTRY_LANGUAGE_SEPARATOR } from '../project/defines';
import { RootState } from '../store';
import { AuthModel } from '../store/auth/authModels';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { hasWarrantyAccess } from '../utils/helpers';
import { getSessionStorageItemTtl, setSessionStorageItemTtl } from '../utils/helpers/sessionStorage';
import { getApiUrl, getUniversFromUrl, loginWithCode, loginConnectAs, downloadFromUrl } from '../project/helpers';
import UserClients from './account/clients';
import Profile from './account/profile';
import Societe from './account/societe';
import ArticleDetails from './article';
import Family from './family';
import OrderHistory from './orderHistory';
import OrderDetail from './orderHistory/orderDetails';
import Product from './product';
import QuoteHistory from './quoteHistory';
import { SearchResults } from './search/search';
import ShoppingCart from './shoppingCart';
import Template from './template';
import WarrantyHistory from './warrantyHistory';
import WarrantyRequest from './warrantyRequest';
import { Univers } from '../store/univers/universModels';
import Loader from '../components/shared/loader';
import { authApi } from '../store/auth/authApi';
import { shoppingCartApi } from '../store/shoppingCart/shoppingCartApi';
import { reloadIsLoggedInFromStorage } from '../store/auth/authSlice';
import { logout } from '../store/componentsSlice';

const AppRoutes: React.FC = () => {
  const language = useAppSelector((state) => state.language.currentLanguage) || DEFAULT_LANGUAGE;
  const country = useAppSelector((state) => state.siteData.currentCountry?.code) || DEFAULT_COUNTRY;
  const location = useLocation();
  const state = useAppSelector((state) => state);
  const authUser = useAppSelector((state) => state.auth);
  const languages = useAppSelector((state) => state.language.languages);
  const univers = useAppSelector((state) => state.siteData.universInfo?.univers);

  const Home = useBrandComponent(BrandComponent.Home);

  const startsWithLangCode = useMemo(() => {
    return (languages ?? [{ lang: 'fr' }])?.some((lang) => location.pathname.startsWith(`/${lang.lang}`) || location.pathname.startsWith(`/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${lang.lang}`));
  }, [languages, country, location.pathname]);

  return (
    <Routes>
      <Route path={APP_URL.RedirectResource} element={<ResourceRedirect/>}/>
      <Route path={APP_URL.Base} element={<Layout hasBrandLayout={false} />}>
        <Route path={APP_URL.Template} element={<Template />} />
      </Route>
      <Route path={APP_URL.Base} element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path={APP_URL.Login} element={<LoginComponentRedirect />} />
        
        {getAppRoutes(location, authUser, language, country, state, univers)}
      </Route>
      <Route path={APP_URL.WsoCallback} element={<WsoCallbackRedirect />} />
      <Route path={APP_URL.ConnectAs} element={<ConnectAs />} />
      {getAppRoutes(location, authUser, language, country, state, univers, false)}
      <Route path="/" element={<Navigate to={`/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`} replace={true} />} />
      <Route
        path="*"
        element={
          <Navigate
          to={startsWithLangCode ? `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}` : `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}${location.pathname + location.search}`}
            replace={true}
          />
        }
      />
    </Routes>
  );
};
export default AppRoutes;

const LoginComponentRedirect = () => {
  const { lang } = useParams();
  return <Navigate to={`/${lang}`} replace={true} />;
};

const ResourceRedirect = () => {
  const location = useLocation();
  const searchPararms = new URLSearchParams(location.search);
  const url = searchPararms.get("url");
  const [ dowloaded, setDownloaded ] = useState<boolean>(false);

  useEffect(()=>{
    if (url && !dowloaded){
      downloadFromUrl(url, (data: any)=>{
        setDownloaded(true);
        window.location = data;
        if (url.indexOf('/export') !== -1) {
          setTimeout(() => {
            window.close();
          }, 200);
        }
      })
      
    }
  },[]);
  return <Loader />;
};

const WsoCallbackRedirect = () => {
  const location = useLocation();
  const searchPararms = new URLSearchParams(location.search);
  const code = searchPararms.get("code");
  const state = searchPararms.get("state");
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.language.currentLanguage);
  const country = useAppSelector((state) => state.siteData?.currentCountry?.code) || DEFAULT_COUNTRY;
  const dispatch = useAppDispatch();

  useEffect(()=>{
    if (!code) {
      dispatch(logout());
      dispatch(reloadIsLoggedInFromStorage());

      window.location.href = `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`
    } else if (!getSessionStorageItemTtl("login")){
      setSessionStorageItemTtl("login", true, 30);
      let univers = state?.split(';')[1] ?? getUniversFromUrl();
      univers = univers.replace('-fr', '');
      let apiUrl = getApiUrl(univers);
      if (univers === "inter"){
        univers = getUniversFromUrl();
      }
      loginWithCode(`${apiUrl}/wso/code/${univers}?code=${code}&state=${state}&callback=${window.location.origin}/wso/callback`,
        (response: any) => {
          dispatch(authApi.util.invalidateTags(['LoggedUser'])); // nice way to invalidate tags from another api
          dispatch(shoppingCartApi.util.invalidateTags(['CartList'])); // nice way to invalidate tags from another api
          dispatch(reloadIsLoggedInFromStorage());
          navigate('/', { replace: true });
          window.location.href = `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`
        },
        (response: any) => {
          navigate('/', { replace: true });
        }
      );
    }
  },[]);
  return <Loader />;
};

const ConnectAs = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.language.currentLanguage);
  const country = useAppSelector((state) => state.siteData?.currentCountry?.code) || DEFAULT_COUNTRY;
  const dispatch = useAppDispatch();

  useEffect(()=>{
    if (!code) {
      navigate('/', { replace: true });
    }
    if (!getSessionStorageItemTtl("login")){
      setSessionStorageItemTtl("login", true, 30);
      let univers = getUniversFromUrl();
      let apiUrl = getApiUrl(univers);
      loginConnectAs(`${apiUrl}/connectas/${code}`,
        (response: any) => {
          dispatch(authApi.util.invalidateTags(['LoggedUser'])); // nice way to invalidate tags from another api
          dispatch(shoppingCartApi.util.invalidateTags(['CartList'])); // nice way to invalidate tags from another api
          dispatch(reloadIsLoggedInFromStorage());
          navigate('/', { replace: true });
          window.location.href = `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`
        },
        (response: any) => {
          navigate('/', { replace: true });
          window.location.href = `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`
        }

      );
      }
  },[]);
  return <Loader />;
};

const getAppRoutes = (
  location: Location,
  authUser: AuthModel,
  language: string,
  country: string,
  state: RootState,
  univers?: Univers,
  isInBaseRoute: boolean = true
) => {
  const isLoggedIn = !!authUser.uuid;

  if (!isLoggedIn && univers?.restricted) return null;

  const loggedInRoutes = [
    { path: APP_URL.ShoppingCart, component: <ShoppingCart /> },
    { path: APP_URL.ShoppingCartSuccess, component: <ShoppingCart /> },
    { path: APP_URL.WarrantyHistory, component: <WarrantyHistory /> },
    { path: APP_URL.OrderHistory, component: <OrderHistory /> },
    { path: APP_URL.OrderDetails, component: <OrderDetail /> },
    { path: APP_URL.Profile, component: <Profile /> },
    { path: APP_URL.Societe, component: <Societe /> },
    { path: APP_URL.Clients, component: <UserClients /> },
    { path: APP_URL.QuoteHistory, component: <QuoteHistory /> },
  ];

  hasWarrantyAccess(state) &&
    loggedInRoutes.push({ path: APP_URL.WarrantyReplacement, component: <WarrantyRequest /> });

  const mainRoutes = [
    { path: APP_URL.Search, component: <SearchResults /> },
    { path: APP_URL.Family, component: <Family /> },
    { path: APP_URL.FamilyDetail, component: <Family /> },
    { path: APP_URL.Product, component: <Product /> },
    { path: APP_URL.ProductSchema, component: <Product /> },
    { path: APP_URL.ProductReseau, component: <Product /> },
    { path: APP_URL.Article, component: <ArticleDetails /> },
    { path: APP_URL.ArticleReseau, component: <ArticleDetails /> },
  ];

  return (
    <>
      {isLoggedIn && (
        <>
          {loggedInRoutes.map((route) => {
            let lang_path = `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`;
            if (!isInBaseRoute && location.pathname && location.pathname.indexOf(lang_path) !== -1){
              lang_path = "";
            }
            return <Route
              key={route.path}
              path={route.path}
              element={
                isInBaseRoute ? (
                  route.component
                ) : (
                  <Navigate to={`${lang_path + location.pathname + location.search}`} replace={true} />
                )
              }
            />
          })}
        </>
      )}
      {mainRoutes.map((route) => {
        let lang_path = `/${country}${DEFAULT_COUNTRY_LANGUAGE_SEPARATOR}${language}`;
        if (!isInBaseRoute && location.pathname && location.pathname.indexOf(lang_path) !== -1){
          lang_path = "";
        }
        return <Route
          key={route.path}
          path={route.path}
          element={
            isInBaseRoute ? (
              route.component
            ) : (
              <Navigate to={`${lang_path + location.pathname + location.search}`} replace={true} />
            )
          }
        />
      })}
    </>
  );
};
