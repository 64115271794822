import { Link } from 'react-router-dom';
import { Article } from '../../store/article/articleModels';
import { APP_URL } from '../../project/defines';
import { Text } from '../shared/text';
import AddToCardButton from './articleAddToCardButton';
import { ArticleUseCaseButton } from './articleUseCases';
import { getAppUrl } from '../../project/helpers';
import Separator from '../shared/separator';

const ArticleCommandPanel: React.FC<{ article: Article }> = ({ article }) => {
  const { articleReseau, article_remplacant } = article;

  return (
    <>
      {article_remplacant && (
        <Link
          data-text-align="left"
          data-outline="none"
          className="button wide large"
          to={getAppUrl(articleReseau ? APP_URL.ArticleReseau : APP_URL.Article, {
            routeParams: {
              id: article_remplacant.uuid || '',
              reseau_uuid: article_remplacant.articleReseau?.reseau_uuid,
            },
          })}
        >
          <Text>article.replacePar</Text>
          {article_remplacant.reference}
        </Link>
      )}
      <Link
        data-text-align="left"
        data-outline="none"
        className="button wide large"
        to={getAppUrl(articleReseau ? APP_URL.ArticleReseau : APP_URL.Article, {
          routeParams: {
            id: article.uuid || '',
            reseau_uuid: articleReseau?.reseau_uuid,
          },
        })}
      >
        <Text>vignettesArticle.buttons.detailArticle.libelle</Text>
        <icon>arrow_outward</icon>
      </Link>
      <Separator horizontal data-margin="none" />
      <AddToCardButton article={article} />
      <Separator horizontal data-margin="none" />
      <ArticleUseCaseButton article={article} isWidget />
    </>
  );
};

export default ArticleCommandPanel;
