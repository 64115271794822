import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../components/shared/button';
import Loader from '../../components/shared/loader';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { formatNumberEuro } from '../../project/helpers';
import { addNotification } from '../../store/componentsSlice';
import { useAppSelector } from '../../store/hooks';
import { useDeleteCartItemMutation, useUpdateCartItemMutation } from '../../store/shoppingCart/shoppingCartApi';
import { setCartData } from '../../store/shoppingCart/shoppingCartSlice';
import useTranslation from '../../utils/hooks/useTranslation';

const CartItem: React.FC<{ isPending?: boolean; cartData: any }> = ({ isPending = false, cartData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [itemQuantity, setItemQuantity] = useState(1);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const [deleteCartItem, { isLoading: isDeleteLoading }] = useDeleteCartItemMutation();
  const [updateCartData, { isLoading: isUpdateLoading }] = useUpdateCartItemMutation();

  useEffect(() => {
    if (cartData?.quantite) {
      setItemQuantity(cartData.quantite);
    }
  }, [cartData]);

  const handleUpdateResponse = (response: any, isDelete: boolean = false) => {
    if (response?.error) {
      dispatch(
        addNotification({
          type: 'error',
          message: t('global.notifications.paniers.titleSingle'),
          description: response?.error.error || t(response.error?.data?.error),
          duration: 5,
        })
      );
    } else {
      dispatch(setCartData(response.data));
      dispatch(
        addNotification({
          type: 'success',
          message: t('global.notifications.paniers.titleSingle'),
          description: t(isDelete ? 'global.notifications.paniers.deleted' : 'global.notifications.paniers.updated'),
          duration: 5,
        })
      );
    }
  };

  const addToCartButton = (
    <Text
      data-type="link"
      data-wrap="wrap"
      onClick={() => {
        updateCartData({ cart_item_uuid: cartData.uuid, en_attente: false, univers_uuid: univers?.uuid }).then(
          handleUpdateResponse
        );
      }}
    >
      panier.articlesEnAttente.ajoutAuPanier
    </Text>
  );

  const addToPendings = (
    <Text
      data-type="link"
      data-wrap="wrap"
      onClick={() => {
        updateCartData({ cart_item_uuid: cartData.uuid, en_attente: true, univers_uuid: univers?.uuid }).then(
          handleUpdateResponse
        );
      }}
    >
      panier.panierEnCours.miseEnAttente
    </Text>
  );

  const onBlurHandler = (e: any) => {
    const qty = Number(e.target.value);
    if (cartData.quantite !== qty && qty !== 0) {
      setItemQuantity(qty);
      updateCartData({
        cart_item_uuid: cartData.uuid,
        quantite: qty,
      }).then(handleUpdateResponse);
    }
  };

  const price = formatNumberEuro(cartData.article.prix_ht_remise);
  const deee =
    !cartData.article.d3e || parseFloat(cartData.article.d3e) === 0
      ? null
      : t('commandes.commande.table.deee') + formatNumberEuro(cartData.article.d3e);
  const eco_contr =
    !cartData.article.eco_contr || parseFloat(cartData.article.eco_contr) === 0
      ? null
      : t('commandes.commande.table.eco_contr') + formatNumberEuro(cartData.article.eco_contr);

  return (
    <div className="item">
      {(isUpdateLoading || isDeleteLoading) && <Loader />}
      <group data-align="center" data-space="10" data-gap="5" data-wrap="no">
        <group data-gap="10">
          <group
            data-width="auto"
            data-direction="column"
            data-contain=""
            data-max-length="250"
            data-position="left"
            data-length="forcefit"
            data-gap="5"
          >
            <group data-gap="5">
              <text data-weight="700">{'[' + cartData.article.reference + '] '}</text>
              <text data-weight="700" data-wrap="wrap">
                {cartData.article.libelle}
              </text>
            </group>
            {isPending ? addToCartButton : addToPendings}
          </group>
          <group data-align="center" data-wrap="no" data-length="120">
            <label className="field small" data-label="left" data-multi-element="" data-length="100" data->
              <div className="form_fields">
                <div className="field_cont">
                  <Button
                    micro
                    icon="remove"
                    disabled={cartData.quantite === 1}
                    onClick={() => {
                      const qty = cartData.quantite - 1;
                      setItemQuantity(qty);
                      updateCartData({
                        cart_item_uuid: cartData.uuid,
                        quantite: qty,
                      }).then(handleUpdateResponse);
                    }}
                  />
                  <Separator vertical />
                  <input
                    data-appearance="none"
                    type="number"
                    data-text-align="center"
                    value={itemQuantity}
                    min={1}
                    max={99}
                    onChange={(e: any) => {
                      let val = Number(e.target.value);
                      setItemQuantity(val < 1 ? 1 : val);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        onBlurHandler(e);
                      }
                    }}
                    onBlur={onBlurHandler}
                  />
                  <Separator vertical />
                  <Button
                    micro
                    icon="add"
                    onClick={() => {
                      const qty = cartData.quantite + 1;
                      setItemQuantity(qty);
                      updateCartData({
                        cart_item_uuid: cartData.uuid,
                        quantite: qty,
                      }).then(handleUpdateResponse);
                    }}
                  />
                  <i></i>
                </div>
              </div>
            </label>
          </group>
        </group>

        <group data-align="center" data-wrap="no" data-max-length="100">
          <group data-align="center" data-gap="5">
            <text data-text-size="medium" data-weight="700" data-position="right" >
              {price}
            </text>
            {deee && <text data-text-size="small">{deee}</text>}
            {eco_contr && <text data-text-size="small">{eco_contr}</text>}
          </group>
        </group>
        <group data-align="center" data-wrap="no" data-width="auto" data-gap="10">
          <Button
            mini
            icon="delete"
            onClick={() => {
              deleteCartItem({
                uuid: cartData.uuid,
                univers_uuid: univers?.uuid!,
              }).then((response: any) => {
                handleUpdateResponse(response, true);
              });
            }}
          />
        </group>
      </group>
    </div>
  );
};
export default CartItem;
