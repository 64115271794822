import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../components/shared/list/table';
import Loader from '../components/shared/loader';
import Scroll from '../components/shared/scroll';
import { Text } from '../components/shared/text';
import { WarrantyArticleLine } from '../pages/warrantyRequest/stage2';
import { APP_URL } from '../project/defines';
import { getApiUrl, getAppUrl, getUniversFromUrl /*, downloadFromUrl*/ } from '../project/helpers';
import { addNotification } from '../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useGetProductQuery } from '../store/product/productApi';
import { Schema, Zone } from '../store/product/productModels';
import { useGetZoneQuery } from '../store/product/zonesApi';
import { currentCountryUuid } from '../store/sideData/siteDataSlice';
import { getKeyUpHandler } from '../utils/helpers';
import useTranslation from '../utils/hooks/useTranslation';
import Separator from './shared/separator';


const ProductInfo: React.FC<{
  id?: string;
  schema_uuid?: string;
  reseau_uuid?: string;
  selectable: boolean;
  setSelectedArticles?: Dispatch<SetStateAction<WarrantyArticleLine[]>>;
  selectedArticles?: any;
  setOpenDetail?: any;
}> = ({ id, schema_uuid, reseau_uuid, selectable, setSelectedArticles, selectedArticles, setOpenDetail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const schemaImageRef = React.useRef<HTMLImageElement>(null);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const [currentSchema, setCurrentSchema] = useState<Schema | null>(null);

  const {
    data: product,
    isError,
    isLoading: isProductLoading,
    isFetching: isProductFetching,
    error,
  } = useGetProductQuery(
    { univers_uuid: univers?.uuid!, lang, pays_uuid, id, schema_uuid: schema_uuid ?? '' },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang }
  );

  const {
    data: zones,
    isError: isZonesError,
    isLoading: isZonesLoading,
    isFetching: isZonesFetching,
  } = useGetZoneQuery(
    { univers_uuid: univers?.uuid!, lang, pays_uuid, id, schema_uuid: schema_uuid ?? currentSchema?.uuid, reseau_uuid },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang || !currentSchema }
  );

  const [gridData, setGridData] = useState<any>(null);
  const isLoading = isZonesLoading || isZonesFetching || isProductFetching || isProductLoading;
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [selectedZones, setSelectedZones] = useState<any[]>([]);
  const [cancelScroll, setCancelScroll] = useState<boolean>(false);
  const [tableHeght, setTableHeght] = useState<number>(0);
  const [redirectUrl, setRedirectUrl] = useState<any>(null);

  type TableRecord = Record<string, any>;
  const columns: ColumnType<TableRecord>[] = [
    {
      key: 'libelle',
      title: t('nomenclature.table.id'),
      dataIndex: 'libelle',
      width: 45,
    },
    {
      key: 'reference',
      title: t('nomenclature.table.ref'),
      dataIndex: 'reference',
      width: 90,
      render: (value: string, record: Record<string, any>) => {
        return <Text>{record.articles[0].reference} </Text>;
      },
    },
    {
      key: 'uuid',
      title: t('nomenclature.table.nom'),
      dataIndex: 'uuid',
      width: 255,
      render: (value: string, record: Record<string, any>) => {
        return (
          <>
            <Text data-wrap="wrap">
              {record.articles[0].refcom2 ? record.articles[0].refcom2 + '-' : ''} {record.articles[0].libelle}
            </Text>
            {record.articles[0].article_nomenclature.periode && (
              <>
                <br />
                <Text data-wrap="wrap" data-accent="" data-space="5">
                  {record.articles[0].article_nomenclature.periode}
                </Text>
              </>
            )}
            {record.articles[0].article_nomenclature.commentaire && (
              <>
                <br />
                <Text data-wrap="wrap" data-space="5">
                  {record.articles[0].article_nomenclature.commentaire}
                </Text>
              </>
            )}
            {record.articles[0].article_nomenclature.complement && (
              <>
                <br />
                <Text data-wrap="wrap" data-space="5">
                  {record.articles[0].article_nomenclature.complement}
                </Text>
              </>
            )}

            {record.libelle_gescom ? (
              <>
                <br />
                <Text data-wrap="wrap">{record.libelle_gescom}</Text>
              </>
            ) : null}
          </>
        );
      },
    },
    {
      key: 'quantite',
      title: t('nomenclature.table.quantite'),
      dataIndex: 'quantite',
      width: 80,
      render: (value: string, record: Record<string, any>) => {
        return <Text>{record.articles[0].article_nomenclature.quantite} </Text>;
      },
    },
  ];

  const zonesUnique: (Zone & { highlightUUIDs: string })[] = useMemo(() => {
    const zonesMap = new Map<string, Zone & { highlightUUIDs: string }>();
    if (zones) {
      zones.forEach((z) => {
        let existing = zonesMap.get(z.articles[0].reference);
        if (existing) {
          existing.highlightUUIDs = existing.highlightUUIDs + ',' + z.uuid;
        } else {
          zonesMap.set(z.articles[0].reference, { ...z, highlightUUIDs: z.uuid });
        }
      });
    }

    return Array.from(zonesMap, ([name, value]) => ({ ...value }));
  }, [zones]);

  useEffect(() => {
    setGridData(null);
  }, [schema_uuid]);


   const searchNoAccent = (input: string, value: string) => {
       let normalizedInput = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
       let normalizedValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
   
       return input.includes(value)|| normalizedInput.includes(normalizedValue);
   }
  const onSearchChange = useCallback(
    (e: any) => {
      if (zonesUnique) {
          const newData = zonesUnique.filter(
              (item: any) =>
                  searchNoAccent(item.articles[0]?.refcom2?.toLowerCase?.() ?? "", e.target.value.toLowerCase?.()) || 
                  searchNoAccent(item.articles[0]?.libelle?.toLowerCase?.() ?? "", e.target.value.toLowerCase?.()) || 
                  searchNoAccent(item.articles[0]?.article_nomenclature.commentaire?.toLowerCase?.() ?? "", e.target.value.toLowerCase?.()) || 
                  searchNoAccent(item.articles[0]?.article_nomenclature.complement?.toLowerCase?.() ?? "", e.target.value.toLowerCase?.()) || 
                  searchNoAccent(item.libelle_gescom?.toLowerCase?.() ?? "", e.target.value.toLowerCase?.()) || 
                  searchNoAccent(item.articles[0]?.reference?.toLowerCase?.() ?? "", e.target.value.toLowerCase?.())
        );
        setGridData(newData);
      }
    },
    [zonesUnique]
  );

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({ type: 'warning', message: 'Produit', description: t((error as any).data.error), duration: 5 })
      );

      navigate(getAppUrl(APP_URL.HomePage), { replace: true });
    }
  }, [error, navigate, t, dispatch]);

  useEffect(() => {
    if (product?.nomenclature?.schemas) {
      setCurrentSchema(
        schema_uuid && product.nomenclature.schemas.length > 1
          ? product.nomenclature.schemas.filter((item: Schema) => item.uuid === schema_uuid)[0]
          : product.nomenclature.schemas[0]
      );
    }
  }, [product, schema_uuid]);

  useEffect(() => {
    if (selectedZone) {
      setTimeout(() => {
        const el = document?.querySelector('.ant-table-body');
        const selected = document?.querySelector('tr.selected') as HTMLElement;
        if (el && selected && !cancelScroll) el.scrollTo({ top: selected.offsetTop, behavior: 'smooth' });
      });
    }
  }, [selectedZone]); //eslint-disable-line

  const mouseEnter = (e: any, data: Zone) => {
    setSelectedZone(data.uuid);
  };

  const mouseLeave = (e: any, data: Zone) => {
    setSelectedZone('');
  };

  useEffect(() => {
    if (schemaImageRef?.current) {
      setTableHeght(schemaImageRef?.current.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      window.open(`${APP_URL.RedirectResource}?url=${escape(redirectUrl)}`, '_blank');
      setRedirectUrl(null);
    }
  }, [redirectUrl]);

  return (
    <>
      {isLoading && !isError && <Loader />}
      {selectable && product && (
        <group
          data-space="20"
          data-align="center"
          data-wrap="no"
          data-sticky="top"
          data-pointer-events="no"
        >
          <div
            data-pointer-events="yes"
            className="button primary"
            data-position="right"
            title={t(
              "garantie.demande.etapes.2.articleSelectionModal.validationButtonTitle"
            )}
            onClick={() => {
              setOpenDetail?.(false);
              setSelectedArticles?.((article: any) => [
                ...article,
                ...selectedZones
                  .filter((item) => {
                    const isValid = !(
                      (!item.articles[0].articleReseau &&
                        !item.articles[0].is_commandable) ||
                      (item.articles[0].articleReseau &&
                        !item.articles[0].articleReseau.isCommandable)
                    );
                    if (!isValid) {
                      dispatch(
                        addNotification({
                          type: "error",
                          message: t(
                            `garantie.demande.etapes.2.errors.noCommandable.title`,
                            {
                              reference: item.articles[0].reference,
                            }
                          ),
                          description: t(
                            `garantie.demande.etapes.2.errors.noCommandable.message`
                          ),
                          duration: 5,
                        })
                      );
                    }
                    return isValid;
                  })
                  .map(
                    (item) =>
                      ({
                        article: item.articles[0],
                        qte: 1,
                      } as WarrantyArticleLine)
                  ),
              ]);
            }}
            onKeyUp={getKeyUpHandler()}
          >
            <Text>
              garantie.demande.etapes.2.articleSelectionModal.validationButtonLabel
            </Text>
          </div>
        </group>
      )}
      {product ? (
        <group data-direction="row" data-gap="10" data-align="start">
          <group>
            <Scroll>
              <group
                data-wrap="no"
                data-align="center"
                data-gap="10"
                className="slider_nav"
              >
                {product?.nomenclature?.schemas &&
                  product.nomenclature.schemas.length > 1 &&
                  product.nomenclature.schemas.map((item: Schema) => {
                    return !selectable ? (
                      <Link
                        key={item.uuid}
                        to={getAppUrl(APP_URL.ProductSchema, {
                          routeParams: {
                            id: product.uuid || "",
                            schema_uuid: item.uuid || "",
                          },
                        })}
                      >
                        <group
                          data-length="260"
                          data-background={
                            item.uuid === currentSchema?.uuid ? "highlight" : ""
                          }
                          data-border-select={
                            item.uuid === currentSchema?.uuid ? "selected" : ""
                          }
                          data-align="center"
                          data-wrap="no"
                          data-radius="5"
                          data-border="inset"
                          data-contain=""
                          data-space="5"
                        >
                          <picture data-ratio="1:1" data-length="60">
                            <img src={item.image} alt="" />
                          </picture>
                          <text
                            data-wrap="wrap"
                            data-text-align="left"
                            data-space="5"
                          >
                            {item.libelle ?? item.nom}
                          </text>
                        </group>
                      </Link>
                    ) : (
                      <group
                        onClick={() => {
                          setCurrentSchema(item);
                        }}
                        data-length="260"
                        data-background={
                          item.uuid === currentSchema?.uuid ? "highlight" : ""
                        }
                        data-align="center"
                        data-wrap="no"
                        data-radius="5"
                        data-border="inset"
                        data-contain=""
                        data-space="5"
                        key={item.uuid}
                      >
                        <picture data-ratio="1:1" data-length="60">
                          <img src={item.image} alt="" />
                        </picture>
                        <text
                          data-wrap="wrap"
                          data-text-align="left"
                          data-space="5"
                        >
                          {item.nom}
                        </text>
                      </group>
                    );
                  })}
              </group>
            </Scroll>
          </group>
          {(!gridData || gridData.length === 0) &&
          (!zonesUnique || zonesUnique.length === 0) &&
          !currentSchema &&
          !isLoading ? (
            <group
              data-space="20"
              data-border=""
              data-radius="10"
              data-direction="column"
                data-gap="20"
                data-background="info"
            >
                <icon data-icon-size="">info</icon>
                <separator horizontal="" data-margin="none"></separator>
              <Text dataWeight="700" data-wrap="wrap" >
                produit.errors.noNomenclature
              </Text>
            </group>
          ) : (
            <group
              data-scroll=""
              data-gap="10"
              data-name="schema"
              data-adaptive="1300"
            >
              <group
                data-background="main-background"
                data-direction="column"
                data-length="500"
                data-radius="10"
                data-contain=""
                data-wrap="no"
                data-border="inset"
                data-space="5"
              >
                <toolbar data-sticky="top" data-backdrop="" data-border="no">
                  <label className="field" data-length="auto">
                    <div className="form_fields">
                      <div className="field_cont">
                        <icon>search</icon>
                        <Separator vertical />
                        <input
                          type="text"
                          key={schema_uuid}
                          placeholder={t("nomenclature.filtre.placeholder")}
                          onChange={onSearchChange}
                        />
                        <i></i>
                      </div>
                    </div>
                  </label>
                </toolbar>
                <Separator horizontal data-margin="none" />
                {zonesUnique?.length > 0 && (
                  <group data-table-highlight="zebra-odd" data-scroll="">
                    <Table
                      data-min-length="500"
                      scroll={{ y: tableHeght || 500 }}
                      keyField="uuid"
                      ellipsis={true}
                      onRow={(record: any, rowIndex) => {
                        return {
                          onMouseEnter: (event) => {
                            setSelectedZone(record.highlightUUIDs);
                            setCancelScroll(true);
                          },
                          onMouseLeave: (event) => {
                            setSelectedZone("");
                            setCancelScroll(false);
                          },
                          onClick: () => {
                            if (!selectable) {
                              navigate(
                                getAppUrl(
                                  record.reseau_uuid
                                    ? APP_URL.ArticleReseau
                                    : APP_URL.Article,
                                  {
                                    routeParams: {
                                      id: record.articles[0].uuid || "",
                                      reseau_uuid: record.reseau_uuid,
                                    },
                                  }
                                )
                              );
                            } else {
                              if (
                                !selectedArticles.some(
                                  (_item: any) =>
                                    _item?.article.uuid ===
                                    record.articles[0].uuid
                                )
                              ) {
                                setSelectedZones((item) => [...item, record]);
                              }
                              if (
                                selectedZones.some(
                                  (_item) => _item.uuid === record.uuid
                                )
                              ) {
                                setSelectedZones((_item) =>
                                  _item.filter(
                                    (_item) => _item.uuid !== record.uuid
                                  )
                                );
                              }
                            }
                          },
                        };
                      }}
                      bordered={false}
                      columns={columns}
                      showHeader={true}
                      tableLayout="fixed"
                      dataSource={gridData || zonesUnique || []}
                      rowClassName={(
                        item: Zone & { highlightUUIDs: string }
                      ) => {
                        return `${
                          (selectedZone &&
                            item?.highlightUUIDs?.includes(selectedZone)) ||
                          selectedZones
                            .map((item) => item.uuid)
                            .includes(item.uuid)
                            ? "selected"
                            : ""
                        } ${
                          selectedArticles
                            ?.map((item: any) => item.article.uuid)
                            .includes(item?.articles[0].uuid)
                            ? "disabled"
                            : ""
                        }`;
                      }}
                      pagination={false}
                      size={"small" as SizeType}
                    />
                  </group>
                )}
              </group>
              <group
                data-border="inset"
                data-space="5"
                data-length="autofit"
                data-direction="column"
                data-radius="10"
                data-scroll=""
              >
                {!selectable && (
                  <>
                    <group data-space="10" data-align="center" data-wrap="no">
                      {(product?.nomenclature?.commentaire ||
                        product?.commentaire) && (
                        <Text data-weight="700" data-wrap="wrap">
                          {product.nomenclature.commentaire}
                          {product?.commentaire}
                        </Text>
                      )}
                      <div
                        className="button mini primary"
                        data-position="right"
                        onClick={() => {

                          let url = `${getApiUrl(getUniversFromUrl())}/nomenclatures/${
                              product.nomenclature_uuid
                            }/pdf?univers_uuid=${
                              univers?.uuid
                            }&lang=${lang}&produit_uuid=${product.uuid}`;

                            setRedirectUrl(url);
                          
                        }}
                        onKeyUp={getKeyUpHandler()}
                      >
                        <icon>print</icon>
                      </div>
                    </group>
                    <Separator horizontal data-margin="none" />
                  </>
                )}

                <group data-space="20" data-scroll="auto">
                  <div
                    className="canvas_wrapper"
                    style={
                      schemaImageRef?.current &&
                      schemaImageRef?.current.width > 740
                        ? {
                            transform: `scale(${
                              740 / schemaImageRef?.current.width
                            })`,
                            transformOrigin: "0 0",
                          }
                        : {}
                    }
                  >
                    <div className="canvas_cont">
                      {currentSchema && (
                        <img
                          ref={schemaImageRef}
                          src={currentSchema.image}
                          alt=""
                        />
                      )}
                    </div>

                    <div className="canvas_pointer_wrap">
                      {zones
                        ?.filter(
                          (item) =>
                            item.margin_left >= 0 &&
                            item.margin_top >= 0 &&
                            item.articles.length > 0 &&
                            item.taille !== "hidden"
                        )
                        .map((item) => {
                          return !selectable ? (
                            <Link
                              key={item.uuid}
                              to={getAppUrl(
                                item.reseau_uuid
                                  ? APP_URL.ArticleReseau
                                  : APP_URL.Article,
                                {
                                  routeParams: {
                                    id: item.articles[0].uuid || "",
                                    reseau_uuid: item.reseau_uuid,
                                  },
                                }
                              )}
                            >
                              <span
                                data-weight="700"
                                data-ratio="1:1"
                                data-space="5"
                                onMouseEnter={(e) => {
                                  mouseEnter(e, item);
                                }}
                                onMouseLeave={(e) => {
                                  mouseLeave(e, item);
                                }}
                                className={`point ${item.taille} ${
                                  selectedZone?.includes(item.uuid)
                                    ? "select"
                                    : ""
                                }`}
                                style={{
                                  transform: `translateX(${item.margin_left}px) translateY(${item.margin_top}px) translateX(-50%) translateY(-50%)`,
                                }}
                              >
                                {item.libelle}
                              </span>
                            </Link>
                          ) : (
                            <span
                              data-weight="700"
                              data-ratio="1:1"
                              data-space="5"
                              key={item.uuid}
                              onMouseEnter={(e) => {
                                mouseEnter(e, item);
                              }}
                              onMouseLeave={(e) => {
                                mouseLeave(e, item);
                              }}
                              className={`point ${item.taille} ${
                                selectedArticles
                                  .map((_item: any) => _item.article.uuid)
                                  .includes(item?.articles[0].uuid)
                                  ? "disabled"
                                  : ""
                              } ${
                                selectedZone?.includes(item.uuid) ||
                                selectedZones
                                  .map((_item) => _item.uuid)
                                  .includes(item.uuid)
                                  ? "select"
                                  : ""
                              }`}
                              style={{
                                transform: `translateX(${item.margin_left}px) translateY(${item.margin_top}px) translateX(-50%) translateY(-50%)`,
                              }}
                              onClick={() => {
                                if (
                                  !selectedArticles.some(
                                    (_item: any) =>
                                      _item?.article.uuid ===
                                      item.articles[0].uuid
                                  )
                                ) {
                                  setSelectedZones((_item) => [..._item, item]);
                                }
                                if (
                                  selectedZones.some(
                                    (_item) => _item.uuid === item.uuid
                                  )
                                ) {
                                  setSelectedZones((prev) =>
                                    prev.filter(
                                      (_item) => _item.uuid !== item.uuid
                                    )
                                  );
                                }
                              }}
                              onKeyUp={getKeyUpHandler()}
                            >
                              {item.libelle}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </group>
              </group>
            </group>
          )}
        </group>
      ) : (
        <group data-space="30" data-justify="center">
          {!isZonesError && <Loader />}
        </group>
      )}
    </>
  );
};
export default ProductInfo;
