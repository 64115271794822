import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { PropsWithChildren } from 'react';
import Table, { ColumnType } from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import { Text } from '../../components/shared/text';
import { Defects, Diagnostics } from '../../store/sav/savModels';
import useTranslation from '../../utils/hooks/useTranslation';

const Sav: React.FC<PropsWithChildren<{ diagnostics: Diagnostics[], defects?: Defects, comments?: any[] }>> = ({ diagnostics, defects, comments }) => {
    const { t } = useTranslation();

    const { pagingProps, sort, setSort } = useList({
        viewTypes: [ListViewType.Table],
    });

    const columns: ColumnType[] = [
        {
            title: t('sav.table.symptome'),
            dataIndex: 'symptome',
            width: 200,
        },
        {
            title: t('sav.table.cause'),
            dataIndex: 'cause',
            width: 200,
        },
        {
            title: t('sav.table.remede'),
            dataIndex: 'remede',
            width: 300,
        },
    ];

    return (
            <view data-scroll="">
                <group data-direction="column" data-gap="10" data-space="20">
                    {comments && comments?.length > 0 &&
                        <group>
                            <Text>sav.commentaires</Text>
                            <div className="list_menu">
                                <ul>
                                    {comments.map((item, index) => <li key={item + index}>{item}</li>)
                                    }
                                </ul>
                            </div>
                        </group>
                    }
                    <group>
                        <text data-uppercase="" data-wrap="wrap">
                            {t('sav.diagnostic', { defaut: defects?.libelle ?? '' })}
                        </text>
                    </group>
                    <group data-background="main-background" data-direction="column">
                        <Table
                            view={ListViewType.Table}
                            columns={columns}
                            dataSource={diagnostics}
                            pagination={false}
                            setSorter={setSort}
                            pagingProps={pagingProps.pagination}
                            sort={sort}
                            keyField="uuid"
                            bordered={true}
                            size={'small' as SizeType}
                            tableLayout='auto'
                        />
                    </group>
                </group>
            </view>
    );
};

export default Sav;
