import { Article } from '../../store/article/articleModels';
import { useAppSelector } from '../../store/hooks';
import { UniversInfo } from '../../store/univers/universModels';
import { renderPrice, getArticlePriceHT, isArticleAvailable, isGroupSSO } from '../../project/helpers';
import { Text } from '../shared/text';
import ArticleInfoHeader from './articleInfoHeader';

const ArticleInfo: React.FC<{ article: Article; isWidget?: boolean }> = ({ article, isWidget }) => {
  const { listeSSO } = useAppSelector((state) => state.siteData.universInfo) ?? ({} as UniversInfo);
  const isLoggedIn = useAppSelector((state) => state.auth.active);
  const isGroup = isGroupSSO(listeSSO);

  const isAvailable = isArticleAvailable(article);

  return (
    <>
      <ArticleInfoHeader article={article} isWidget={isWidget} />

      {(isLoggedIn || !isGroup) && article.articleReseau && (
        <group data-direction="column">
          {getArticlePriceHT(article, listeSSO, isLoggedIn) > 0 && (
            <group data-adaptive="450" data-direction="row" data-gap="5" data-wrap="no">
              <group data-length="autofit" data-align="center">
                <Text data-wrap="wrap">vignettesArticle.prixPublicHT</Text>
              </group>
              <Text data-accent="" data-weight="700" data-text-size="large" data-user-select="text">
                {renderPrice(article?.articleReseau.prix_ht ?? 0)}
              </Text>
            </group>
          )}
          {isAvailable && (article.articleReseau.prix_ht_remise as number) > 0 && isLoggedIn && (
            <group data-adaptive="450" data-direction="row" data-gap="5" data-wrap="no">
              <group data-length="autofit" data-align="center">
                <Text data-wrap="wrap">
                  {isLoggedIn && !isGroupSSO(listeSSO) ? 'vignettesArticle.prixRemise' : 'vignettesArticle.prixProHT'}
                </Text>
              </group>

              <Text data-accent="" data-weight="700" data-text-size="large" data-user-select="text">
                {renderPrice(article?.articleReseau.prix_ht_remise ?? 0)}
              </Text>
            </group>
          )}
          {isAvailable && (isLoggedIn || !isGroup) && article.articleReseau.deee > 0 && (
            <group data-adaptive="450" data-direction="row" data-gap="5" data-wrap="no">
              <group data-length="autofit" data-align="center">
                <Text data-wrap="wrap">vignettesArticle.prixEcoPart</Text>
              </group>

              <Text data-accent="" data-weight="700" data-text-size="large">
                {renderPrice(article?.articleReseau.deee || 0)}
              </Text>
            </group>
          )}
          {isAvailable &&
            !!article.articleReseau.prix_ht &&
            !!article.articleReseau.poids &&
            !!article.articleReseau.pu_pmcb && (
              <group data-adaptive="450" data-direction="row" data-gap="5" data-wrap="no">
                <group data-length="autofit" data-align="center">
                  <Text data-wrap="wrap">vignettesArticle.prixEcoCont</Text>
                </group>

                <Text data-accent="" data-weight="700" data-text-size="large">
                  {renderPrice(Math.max(article.articleReseau.poids * article.articleReseau.pu_pmcb * 1, 0.01))}
                </Text>
              </group>
            )}
        </group>
      )}

      {article.articleReseau ? <Text data-wrap="wrap">{article.articleReseau.libelle_gescom}</Text> : ''}
    </>
  );
};

export default ArticleInfo;
