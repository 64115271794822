// Need to use the React-specific entry point to import createApi
import { createApi /*, fetchBaseQuery*/ } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

export const paymentApi = createApi({
  reducerPath: 'payment',
 //baseQuery: fetchBaseQuery({ credentials: 'include' }),
  baseQuery: baseQuery(''),
  endpoints: (builder) => {
    return {
      checkPayment: builder.mutation<any, { url: string }>({
        query: ({ url }) => {
          return {
            url,
            method: 'get',
            responseHandler: async (response: Response) => {
              if (response.status === 200) {
                const text = await response.text();
                return Promise.resolve(text);
              } else {
                return Promise.reject(response);
              }
            },
          };
        },
      }),
    };
  },
});

export const { useCheckPaymentMutation } = paymentApi;
