import classNames from 'classnames';
import React, { PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import { addPopup, removePopup } from '../../../store/componentsSlice';
import { useAppDispatch } from '../../../store/hooks';
import useOnClickOutside from '../../../utils/hooks/outsideClickHandler';
import Button from '../button';
import { Text } from '../text';

export const popupRoot: Element = document.getElementById('popup-root') as Element;

const Popup: React.FC<
  PropsWithChildren<{
    fullscreen?: boolean;
    noHeader?: boolean;
    onClose: () => void;
    fixSize?: string;
    windowContProps?: any;
    closeOnOutsideClick?: boolean;
    refOpener?: RefObject<HTMLElement>;
    title?: string;
    coloredTitle?: boolean;
  }>
> = ({
  fullscreen = false,
  noHeader = false,
  onClose,
  closeOnOutsideClick = true,
  refOpener,
  windowContProps,
  children,
  title = '',
  fixSize,
  coloredTitle = false,
}) => {
  const dispatch = useAppDispatch();
  const [isMount, setIsMount] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const elementRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(elementRef, closeOnOutsideClick ? onClose : undefined, refOpener); // some temporary solution

  useEffect(() => {
    setIsMount(true);
    dispatch(addPopup());
    document.body.classList.add('lock_scroll');

    return () => {
      dispatch(removePopup());
      setIsMount(false);
      document.body.classList.remove('lock_scroll');
    };
  }, []); // eslint-disable-line
  const closeButton = (
    <Button
      mini
      rounded
      material
      {...(fullscreen ? null : { position: 'right' })}
      className="close"
      icon={fullscreen ? 'chevron_left' : 'close'}
      onClick={onClose}
    />
  );
  let innerContent = (
    <div ref={elementRef}>
      {!noHeader && (
        <toolbar {...(coloredTitle ? { 'data-accent': '' } : null)} className="handle">
          <group data-wrap="no" data-align="center">
            {fullscreen && closeButton}
            <Text data-weight="700" data-space="10" data-ellipsis="" data-name="popup_title">
              {title}
            </Text>
            {!fullscreen && closeButton}
          </group>
        </toolbar>
      )}
      <div>
        <div id="windowContent" className="window_cont" {...(windowContProps ?? null)} data-fixed={fixSize}>
          {isMount && children}
        </div>
      </div>
    </div>
  );
  if (!fullscreen) {
    innerContent = (
      <Draggable nodeRef={elementRef} cancel=".window_cont,.close" bounds="parent" handle=".handle">
        {innerContent}
      </Draggable>
    );
  }

  return createPortal(
    <div ref={containerRef} className={classNames('popup', 'open', { fullscreen })}>
      {innerContent}
    </div>,
    popupRoot
  );
};

export default Popup;
