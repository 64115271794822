import Button from '../../components/shared/button';
import { SatcFamillesModel } from '../../store/satc/satcModels';
import { copyToClipboard } from '../../utils/helpers';
import useTranslation from '../../utils/hooks/useTranslation';


const TabExpertQualite: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { defauts } = data;
  const { t } = useTranslation();

  return (
    <group
      data-space="1"
      data-space-vertical="20"
      data-gap="20"
      width="initial"
      direction="row"
    >
      <group
        direction="row"
        data-gap="10"
        data-type="grid"
        data-grid-size="330"
        data-align="start"
      >
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t("satc.detail.tabs.expertQualite.first")}</th>
                <th>{t("satc.detail.tabs.expertQualite.second")}</th>
                <th>{t("satc.detail.tabs.expertQualite.third")}</th>
                <th>{t("satc.detail.tabs.videoTutos.second")}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(defauts).map((key: any) => {
                let item = defauts[key];

                return (
                  <tr key={key}>
                    <td>
                      <picture data-radius="5">
                        <img src={item.thumbnail} alt="" />
                      </picture>
                    </td>
                    <td>
                      <wrap>
                        <text data-wrap="wrap">
                          {item.document_description}
                        </text>
                      </wrap>
                    </td>
                    <td>
                      <wrap>
                        <a
                          data-type="link"
                          href={item.download}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <icon>description</icon>
                        </a>
                      </wrap>
                    </td>
                    <td>
                      <Button
                        extra
                        onClick={() => copyToClipboard(t, item.download)}
                      >
                        <icon>content_copy</icon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </group>
    </group>
  );
};

export default TabExpertQualite;
