import { Tooltip } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { getKeyUpHandler } from '../../../utils/helpers';
import useTranslation from '../../../utils/hooks/useTranslation';
import Separator from '../separator';

export interface NavigationItemProps {
  key?: string;
  accessKey?: string;
  text?: string;
  icon?: string;
  svg?: React.ReactNode;
  innerContent?: React.ReactNode;
  link?: string;
  tooltip?: string;
  url?: string;
  count?: number;
  accent?: boolean;
  className?: string;
  separator?: boolean;
  selected?: boolean;
  index?: number;
  render?: (props?: any) => React.ReactNode;
  iconProps?: IconProps;
  liProps?: any;
  textProps?: any;
  onClick?: (item?: any) => void;
}

export interface IconProps {
  color?: string;
  weight?: string | number;
  size?: string;
  fill?: boolean;
}

const NavigationItem: React.FC<PropsWithChildren<NavigationItemProps>> = ({
  text,
  link,
  url,
  icon,
  svg,
  innerContent,
  count,
  accent,
  className,
  separator,
  selected,
  index,
  onClick,
  iconProps,
  tooltip,
  liProps,
  textProps,
}) => {
  const { t } = useTranslation();
  accent && (liProps['data-accent'] = '');
  let iconStyleProps: any = {};
  iconProps?.size && (iconStyleProps['data-icon-size'] = iconProps.size); //'medium';
  iconProps?.weight && (iconStyleProps['data-icon-weight'] = iconProps.weight); //'100';
  iconProps?.fill && (iconStyleProps['data-fill'] = ''); //'100';
  iconProps?.color && (iconStyleProps['data-color'] = iconProps.color); //'100';

  let content = (
    <>
      {icon && <icon {...iconStyleProps}>{icon}</icon>}
      {svg && <icon {...iconStyleProps}>{svg}</icon>}
      {text && <text {...textProps}>{t(text)}</text>}
      {innerContent}
    </>
  );

  if (link) {
    content = <Link to={link}>{content}</Link>;
  } else if (url) {
    content = (
      <a href={url} target="_blank" rel="noreferrer">
        {content}
      </a>
    );
  }

  const resultItem = (
    <li {...liProps} className={classNames(className, { selected })} onClick={onClick} onKeyUp={getKeyUpHandler()}>
      {index !== 0 && separator && !selected && !accent && <Separator vertical />}
      {!!count && <div className="counter">{count}</div>}
      {content}
    </li>
  );

  return tooltip ? (
    <Tooltip placement="top" title={t(tooltip)}>
      {resultItem}
    </Tooltip>
  ) : (
    resultItem
  );
};

export default NavigationItem;
